import React from "react";
import Card from "../Card";

const Articles = ({ articles }) => {
    const leftArticlesCount = Math.ceil(articles.length / 5);
    // const leftArticles = articles.slice(0, leftArticlesCount);
    const rightArticles = articles.slice(leftArticlesCount, articles.length);

    return (
        <div>  
            {/* < div class="w-2/5">
                {
                leftArticles.map((article, i) => {
                    return <Card article={article} key={`article__${article.slug}`} />;
                })
                }
            </div > */}
            <div class="grid grid-cols-2 lg:grid lg:grid-cols-4 gap-16 lg:gap-8">
                {rightArticles.map((article, i) => {
                    return <Card article={article} key={`article__${article.slug}`} />;
                })}
            </div> 
            </div>
    );
};

export default Articles;