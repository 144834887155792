import React, { Component } from "react";

import Education from '../Education/Education'
import Working from '../Working/Working'
export default class Home extends Component {
    render() {
        return (
        <>
        <div className="w-full h-96">
            <div className="flex items-center justify-center w-full h-full pt-56">
                <div className="text-center">
                    <h1 className="text-4xl lg:text-5xl md:text-5xl  text-gray-400 font-light text-left text-loase tracking-widest">
                    i-Develop i-Design i-Build <br />
                    from Selangor my</h1>
                </div>
            </div>
        </div>
        <div class="pt-40 pb-16">
            <div class="flex mt-32 space-x-36 justify-center">
               <p class="text-2xl font-semibold text-gray-400">React Js</p>
               <p class="text-2xl font-semibold text-gray-400">Strapi</p>
               <p class="text-2xl font-semibold text-gray-400">Firebase</p>
               <p class="text-2xl font-semibold text-gray-400">Wordpress</p>
            </div>
        </div>
            <Education />
            <Working />
        </>
        )
    }
}
