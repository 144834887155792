import React from "react";
import { useQuery } from "@apollo/react-hooks";

const Query = ({ children, query, slug }) => {
    const { data, loading, error } = useQuery(query, {
        variables: { slug: slug }
    });

    if (loading) return (
        <div class="flex items-center justify-center w-full h-full p-24">
            <div class="flex justify-center items-center space-x-1 text-sm text-gray-400">
                    <svg fill='none' class="w-8 h-8 animate-spin" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
                        <path clip-rule='evenodd'
                        d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                        fill='currentColor' fill-rule='evenodd' />
                    </svg>  
                <div>Loading ...</div>
            </div>
        </div>
    )
    
    if (error) return <p>Error: {JSON.stringify(error)}</p>;
    return children({ data });
};

export default Query;