import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

const Card = ({ article }) => {
    const imageUrl =
        process.env.NODE_ENV !== "development"
            ? article.image.url
            : process.env.REACT_APP_BACKEND_URL + article.image.url;
    return (
            <div>    
            <div className="pt-5 lg:pt-5">
                <Link  to={`/article/${article.slug}`}>
                    <img
                    src={imageUrl}
                    alt={article.image.url}
                    alt="blogimage"
                    />
                </Link>
                </div>
                <div>
                <div class="flex items-center pt-2 left-0">
                    <p class="bg-gray-800 text-gray-400 font-bold lg:text-sm md:text-xl font-regular px-2 py-1 rounded ">
                    <Moment format="MMM Do YYYY">{article.published_at}</Moment>
                    </p>
                </div>
                <div class="pt-6">
                <Link  to={`/article/${article.slug}`}>
                    <h2 class="text-2xl font-meduim text-white hover:text-gray-400" id="title" >
                        {article.title}
                    </h2>
                </Link>
                </div>
            </div>
            
            </div>
    );
};

export default Card;