import React from 'react'

function MainInfo() {
    return (
        <div class="h-44 flex items-center justify-center bg-gray-200">
            <div class="text-4xl lg:text-2xl md:text-3xl font-regular text-gray-400">
                <a href="data/hatem.pdf" download>Download Resume</a>
            </div>
        </div>
    )
}

export default MainInfo
