import React from "react";
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";

import ARTICLES_QUERY from "../../queries/article/articles";


const Article = () => {
    let { id } = useParams();

    return (
        <Query query={ARTICLES_QUERY} slug={id}>
            {({ data: { articles } }) => {
                if (articles.length) {
                    // const imageUrl =
                    //     process.env.NODE_ENV !== "development"
                    //         ? articles[0].image.url
                    //         : process.env.REACT_APP_BACKEND_URL + articles[0].image.url;
                    return (
                        <div class="px-24 pt-16 pb-24 lg:px-24 lg:pt-24 lg:pb-24">
                            <div >
                                <div class="flex items-center pt-2 left-0">
                                    <p class="bg-gray-800 text-gray-400 font-bold lg:text-sm md:text-xl font-regular px-2 py-1 rounded ">
                                    <Moment format="MMM Do YYYY">{articles[0].published_at}</Moment>
                                    </p>
                                </div>
                                <div>
                                    <h1 class="text-3xl font-meduim text-white pt-10">
                                        <span class="text-2xl font-bold text-gray-400">Tilte:</span> <br /> {articles[0].title}
                                    </h1>
                                </div>
                                <div class="lg:text-base md:text-2xl font-meduim text-white pt-10 pb-10" >
                                    <p class="lg:text-2xl md:text-xl font-bold text-gray-400 pb-2">Content:</p>
                                    <ReactMarkdown>{articles[0].content}</ReactMarkdown>
                                </div>
                            </div>
                        </div>
                    );
                }
            }}
        </Query >
    );
};

export default Article;