import React, {useState, useEffect} from "react";
import { Switch, Route } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";


import Nav from "../../components/Nav/index";
import Home from '../../components/Home'
import Article from "../Article";
import Category from "../Category";
import MainInfo from '../../components/Maininfo/MainInfo'
import Footer from '../../components/Footer/Footer'

function App() {
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 8000)
    }, [])

    return (
        <div className="App">
            {
            loading ?
                    <div class="h-screen flex items-center justify-center">
                        <PuffLoader
                        color={'#FFFFFF'}
                        loading={loading}
                        size={40} /> 
                    </div>       
                    :
            <div>
            <Nav />
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/article/:id" component={Article} exact />
                <Route path="/category/:id" component={Category} exact />
            </Switch>
            <MainInfo />
            <Footer />    
            </div>        
            }
        </div>
    );
}

export default App;