import React from "react";
import { useParams } from "react-router";
import Articles from "../../components/Articles";
import Query from "../../components/Query";
import CATEGORY_ARTICLES_QUERY from "../../queries/category/articles";


const Category = () => {
    let { id } = useParams();

    return (
        <Query query={CATEGORY_ARTICLES_QUERY} slug={id}>
            {({ data: { categories } }) => {
                if (categories.length) {
                    return (
                        <div>
                            <div class="px-24 pt-10 pb-24 lg:px-24 lg:pt-24 lg:pb-24">
                                <div class="pt-16 lg:pt-0">
                                    <Articles articles={categories[0].articles} />
                                </div>
                            </div>   
                        </div>
                    );
                }
            }}
        </Query>
    );
};

export default Category;