import React, { useState } from "react";
import Query from "../Query";
import { Link } from "react-router-dom";
import { Transition } from "@headlessui/react";
import CATEGORIES_QUERY from "../../queries/category/categories";


const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
        <Query query={CATEGORIES_QUERY} id={null}>
            {({ data: { categories } }) => {
                return (
                    <div>
                        <nav>
                          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
                            <div className="flex items-center justify-between pt-10 pb-5">
                              <div className="lg:ml-0 flex items-center">
                              
                                <div className="flex-shrink-0 ">
                                <Link to="/">
                                  <img
                                    className="h-12 w-8 lg:h-8 lg:w-5 "
                                    src="/Hatem.png"
                                    alt="logo"
                                  />
                                  </Link>
                                </div>
                            
                
                                <div className="hidden md:block">
                                  <div className="ml-40 flex items-baseline space-x-16 ">  
                                    <Link to="/"
                                      className="text-gray-400 hover:text-gray-100 px-3 py-2 rounded-md text-3xl lg:text-base font-medium"
                                    >
                                      Home
                                    </Link>
                                    {categories.map((category, i) => {
                                      return (
                                        <div class="text-gray-400 hover:text-gray-100 px-3 py-2 rounded-md text-3xl lg:text-base font-medium" key={category.slug}>
                                            <Link 
                                              to={`/category/${category.slug}`}        
                                             >
                                            {category.name}
                                              </Link>
                                        </div>
                                      );
                                     })}
                                  </div>
                                </div>
                              </div>
                              
                              <div className="-mr-2 flex md:hidden">
                                <button
                                  onClick={() => setIsOpen(!isOpen)}
                                  type="button"
                                  className=" inline-flex items-center justify-center p-2 text-gray-400 "
                                  aria-controls="mobile-menu"
                                  aria-expanded="false"
                                >
                                  <span className="sr-only">Open main menu</span>
                                  {!isOpen ? (
                                    <svg
                                      className="block h-6 w-6"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      className="block h-6 w-6"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                  
                          <Transition
                            show={isOpen}
                            enter="transition ease-out duration-100 transform"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="transition ease-in duration-75 transform"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                          >
                            {(ref) => (
                            <div className="md:hidden block w-full absolute bg-black" id="mobile-menu">
                                <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                <Link to="/"
                                    className="text-gray-400 hover:text-gray-100 block px-3 py-2 text-base font-medium"
                                >
                                    Home
                                </Link>
                            <div>
                            {categories.map((category, i) => {
                            return (
                            <div className="text-gray-400 hover:text-gray-100 px-3 py-2 rounded-md text-base font-medium" key={category.slug}>
                                <Link to={`/category/${category.slug}`}>
                                    {category.name}
                                </Link>
                            </div>
                            );
                            })}
                        </div>
                        </div>
                        </div>
                            )}
                          </Transition>
                        </nav>
                      </div>
                        );
                    }}
            </Query>
        </>
    );
};

export default Nav;