import React from 'react'

function Work() {
    return (
        <section>
            <div class="p-10 lg:p-24 md:p-24 lg:px-12 md:px-28">
                <div class="container lg:flex flex-col items-start md:flex-row ">
                    <div class="flex flex-col w-full lg:sticky md:top-36 lg:w-2/3">
                        <h2 class="lg:text-xl md:text-4xl font-regular text-gray-200">My Current Position</h2>
                        <h1 class="text-3xl lg:text-6xl md:text-4xl font-semibold text-white hover:text-gray-400 pt-3">Managing Director </h1>
                        <h2 class="lg:text-xl md:text-4xl font-regular text-gray-200 pt-3">
                            Wug Accoustics - Selangor, Malaysia
                        </h2>
                    </div>
                    <div class="lg:w-2/3 pt-10 lg:pt-16">
                        <div class="container mx-auto">
                            <div class="relative wrap overflow-hidden ">
                                <div class="mb-8 lg:flex lg:justify-between lg:flex-row-reverse ">
                                    <div class="order-1 lg:w-5/12"></div>
                                    <div class="order-1 py-4">
                                        <p class="mb-3 text-xl lg:text-xl md:text-2xl text-gray-200">2013-2014</p>
                                        <h2 class="mb-3 text-2xl lg:text-xl md:text-4xl font-medium text-white tracking-loose hover:text-gray-400">
                                            Shop Owner - Resaler</h2>
                                        <h3 class="mb-3 text-lg font-light text-gray-200">
                                            Teknok / Benghazi, Libya
                                        </h3>
                                    </div>
                                </div>
                                <div class="mb-8 flex lg:justify-between">
                                    <div class="order-1"></div>
                                    <div class="order-1 py-4">
                                        <p class="mb-3 text-xl lg:text-xl md:text-2xl font-regular text-gray-400">2015-2016</p>
                                        <h2 class="mb-3 text-2xl lg:text-xl md:text-4xl font-medium text-white tracking-loose hover:text-gray-400">
                                            Wordpress Developer</h2>
                                        <h3 class="mb-3 text-xl lg:text-xl md:text-3xl font-regular text-gray-400">
                                            Aca.gov.ly - Benghazi, Libya
                                        </h3>
                                    </div>
                                </div>
                                <div class="mb-8 lg:flex lg:justify-between lg:flex-row-reverse ">
                                    <div class="order-1 lg:w-5/12"></div>
                                    <div class="order-1 py-4">
                                        <p class="mb-3 text-xl lg:text-xl md:text-2xl font-regular text-gray-400"> 2018-2019</p>
                                        <h2 class="mb-3 text-2xl lg:text-xl md:text-4xl font-medium text-white tracking-loose hover:text-gray-400">
                                            Front-End Developer</h2>
                                        <h3 class="mb-3 text-xl lg:text-xl md:text-3xl font-regular text-gray-400">
                                            Mina - Kuala Lumpur, Malaysia
                                        </h3>
                                    </div>
                                </div>
                                <div class="mb-8 flex lg:justify-between">
                                    <div class="order-1"></div>
                                    <div class="order-1 py-4">
                                        <p class="mb-3 text-xl lg:text-xl md:text-2xl font-regular text-gray-400">2019-2020</p>
                                        <h2 class="mb-3 text-2xl lg:text-xl md:text-4xl font-medium text-white tracking-loose hover:text-gray-400">Content Specialist</h2>
                                        <h3 class="mb-3 text-xl lg:text-xl md:text-3xl font-regular text-gray-400">
                                            Content Nation - Kuala Lumpur, Malaysia
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Work
